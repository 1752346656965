<template>
  <section>
    <a-page-header @back="$router.push({ name: 'main' })" title="Забронированные"
      style="padding: 1.25rem 0 0.25rem; margin-bottom: 0; max-width: 878px !important;">
      <template #extra>
        <a-button size="large" type="primary">
          <router-link :to="{ name: 'mapView', params: { id: 'root' } }">Новое бронирование</router-link>
        </a-button>
      </template>
    </a-page-header>

    <main>
      <a-row :gutter="54">
        <a-col :span="16" style="flex: 1 0 66.666667% !important; max-width: 926px">
          <main v-if="data?.length > 0 || loading" class="ant-spin-nested-loading">
            <div class="spin-container" v-if="loading">
              <a-spin />
            </div>
            <section class="ant-spin-container" :class="{ 'ant-spin-blur': loading }">
              <!-- <p>Ближайшие бронирования</p> -->
              <list-reservation-card v-for="(reservation) in data" :key="reservation?.id" :reservation="reservation"
                :disabled="loading" @cancel="onCancelReservation" @finish="onFinishReservation" />
            </section>
          </main>
          <main v-else>
            <a-empty>
              <template #description>
                Здесь будут отображаться ваши бронирования. <br />
                Вы можете создавать, изменять и отменять их. <br /><br />
                Создайте своё <router-link :to="{ name: 'mapView', params: { id: 'root' } }">первое
                  бронирование!</router-link>
              </template>
            </a-empty>
          </main>
        </a-col>
      </a-row>
    </main>

  </section>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { getListData as getReservationsData, requestAPI as reservationAPI } from "@/compositions/reservations";
import ListReservationCard from "@/components/admin/reservation/ListReservationCard";
import { notification } from "ant-design-vue";

export default {
  name: "UserReservationListPage",
  setup() {
    const {
      loading, data,
      initFilterDefaults, findReservation,
      clientMode, searchMode,
    } = getReservationsData();
    const { removeReservation, finishReservation } = reservationAPI();

    onMounted(async () => {
      searchMode.value = 'reservations';
      clientMode.value = 'user';
      await initFilterDefaults();
      await findReservation();
    });

    async function onCancelReservation(reservationId) {
      await removeReservation(reservationId);
      await findReservation();
      notification.success({
        message: 'Успех',
        description: 'Бронирование удалено',
      });
    }

    async function onFinishReservation(reservationId) {
      await finishReservation(reservationId);
      await findReservation();
      notification.success({
        message: 'Успех',
        description: 'Бронирование завершено',
      });
    }

    return {
      loading,
      data,
      onCancelReservation, onFinishReservation,
    };
  },
  components: { ListReservationCard }
}
</script>
